<template>
  <teleport to="body">
    <TransitionRoot
      enter="ease-in-out duration-150"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in-out duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      as="template"
      appear
      :show="generalStore.systemOverlay"
    >
      <div
        class="fixed z-[999] top-[calc(var(--header-height)+var(--discount-compaign-visible-height))] inset-0 bg-black/25 backdrop-blur-[2.5px] transition-opacity"
      />
    </TransitionRoot>
  </teleport>
</template>

<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";

const generalStore = useGeneralStore();
</script>
