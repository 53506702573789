<template>
  <NuxtLink
    to="#main-content"
    class="visually-hidden"
    :aria-label="$t('scrollToMainContent')"
  >
    {{ $t("scrollToMainContent") }}
  </NuxtLink>
  <CommonAppHeader />
  <main
    id="main-content"
    class="mx-auto flex w-full max-w-[1920px] flex-auto flex-col"
  >
    <slot />
  </main>
  <CommonFooterCertificates />
  <CommonFooterBottomInfo />
  <CommonAppFooter />
  <DialogsMarketingPopup />
  <CommonAppOverlay />
</template>
