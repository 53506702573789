<template>
  <ul class="flex flex-wrap justify-center gap-x-13 gap-y-5 lg:justify-start">
    <li v-for="(link, idx) in socialsFooterMenu" :key="idx">
      <a
        :href="link.url"
        :aria-label="link?.currentTranslation?.title"
        target="_blank"
        class="hover:opacity-80"
      >
        <UiLazyImg
          :src="link?.icon || ''"
          :alt="link?.currentTranslation?.title"
          class="h-6 w-6 object-contain"
        />
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { toTree } from "~/utils";
import { useGeneralStore } from "~/stores/general";

const { socialsMenu } = useGeneralStore();

const socialsFooterMenu = computed(() => {
  return toTree(socialsMenu);
});
</script>
