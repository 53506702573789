<template>
  <BlocksBlockRichText
    v-if="generalStore.footer.certificatesEntities"
    :data="generalStore.footer.certificatesEntities"
  />
</template>

<script setup lang="ts">
import {useGeneralStore} from "~/stores/general";

const generalStore = useGeneralStore();
</script>