<template>
  <ul class="w-full py-2.5">
    <li
      v-for="(block, idx) in mobileFooterMenu"
      :key="block?.currentTranslation?.title || idx"
      class="mb-2"
      :class="`mobile-menu-item-${idx}`"
    >
      <div
        class="flex cursor-pointer select-none items-center justify-between border-b border-white py-3 text-white"
        @click="toggleActive(idx)"
      >
        <div class="ui-text-m">
          {{ block?.currentTranslation?.title }}
        </div>
        <div>
          <UiIcon
            class="h-6 w-6"
            :name="activeItem !== idx ? 'chevron_down' : 'chevron_up'"
          />
        </div>
      </div>
      <SharedCollapseTransition>
        <ul v-show="activeItem === idx" class="ui-text-m px-2.5 text-white">
          <li
            v-for="el in block.children"
            :key="el?.currentTranslation?.title"
            class="my-5"
          >
            <component
              :is="getComponentType(el)"
              :id="el.url.includes('btn-id-') ? extractButtonId(el.url) : null"
              :to="
                !el.is_title && !el.url.includes('btn-id-')
                  ? localePath(el.url)
                  : null
              "
              class="inline-flex text-sm font-light"
              :class="!el.is_title && 'cursor-pointer'"
            >
              <UiLazyImg
                v-if="el.icon"
                :src="el.icon || ''"
                :alt="el?.currentTranslation?.title || 'Icon'"
                class="mr-1.5 h-4 w-4"
              />
              <span>{{ el?.currentTranslation?.title }}</span>
            </component>
          </li>
        </ul>
      </SharedCollapseTransition>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { extractButtonId } from "~/utils";
import { NuxtLink } from "#components";
import { useGeneralStore } from "~/stores/general";
import { NavMenuItem } from "~/types/api/bootstrap.types";
const { footerMenuMobile } = useGeneralStore();
const activeItem = ref(-1);
const localePath = useLocalePathPolyfill();

const mobileFooterMenu = computed(() => {
  return toTree(footerMenuMobile);
});

function toggleActive(idx: number) {
  if (idx === activeItem.value) {
    activeItem.value = -1;
  } else {
    activeItem.value = idx;
    setTimeout(() => {
      const mobileMenuElement: HTMLElement | null =
        document?.querySelector(`.mobile-menu-item-${idx}`) || null;
      scrollToElement(mobileMenuElement);
    }, 200);
  }
}

function scrollToElement(element: HTMLElement | null) {
  if (!element) {
    return;
  }
  const headerOffset: number =
    document?.querySelector("header")?.getBoundingClientRect()?.height || 0;
  const yPosition: number =
    element.getBoundingClientRect().top + window.scrollY - headerOffset;
  window.scrollTo({ top: yPosition, behavior: "smooth" });
}

function getComponentType(link: NavMenuItem) {
  if (link.is_title) {
    return "div";
  } else if (link.url.includes("btn-id-")) {
    return "button";
  } else {
    return NuxtLink;
  }
}
</script>
